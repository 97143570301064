import React from 'react';

const Pagination = (props) => {
    const {postsPerPage, totalPosts, paginate} = props
    const pageNumbers = [];

for(let i=1; i <= Math.ceil(totalPosts / postsPerPage); i++){
    pageNumbers.push(i);
}
console.log(postsPerPage,totalPosts,pageNumbers);
    return (
        <div className="d-flex justify-content-center mt-4">
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className="page-item">
                        <a onClick={()=>paginate(number)}  className="page-link">
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Pagination;
