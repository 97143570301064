import React, { useEffect, useState } from 'react'
import { RoundCard, SecOne, SecSlider, RecentListByCategories } from '../section';
import './home.css';
import { getRequest, postRequest } from '../../services/ApiServices';

export default function Home() {
  const [categoryList, setCategoryList] = useState();
  const [sliderList, setSliderList] = useState();
  const sliderUrl ={image: [
      {src:"./assets/images/slider/w1.jpg", alt:"slider5"},
      {src:"./assets/images/slider/w2.jpg", alt:"slider6"},
      {src:"./assets/images/slider/w3.jpg", alt:"slider7"},
      {src:"./assets/images/slider/w4.jpg", alt:"slider8"},
  ]}
  const sliderRsponsive = {
      superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1},
      desktop: {breakpoint: { max: 3000, min: 1024 },items: 1},
      tablet: {breakpoint: { max: 1024, min: 464 },items: 1},
      mobile: {breakpoint: { max: 4000, min: 0 },items: 1}
  };
  useEffect(() => {
    getCategoryList();
    getBannerList({cat_url:'home', sub_cat_url:''});
  }, []);
  const getCategoryList = () => { 
    getRequest(`${process.env.REACT_APP_API_URL}category/site/category/getAllByActiveWithSubCat`)
    .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          setCategoryList(res.data.data);
        } else {
          setCategoryList([]);
        }
    }).catch((error) => {
      setCategoryList([]);
    });
  }
  
  const getBannerList = (data) => { 
    postRequest(`${process.env.REACT_APP_API_URL}bannerlist/site/bl/getAllByActiveWithCat`,data )
    .then((res) => {
        console.log(res)
        if (res.status == 200) {
          let rslider  = [];
          if(res.data.data.length){
            rslider = res.data.data;
            console.log(rslider);
            rslider[0].images = JSON.parse(rslider[0].images);
            console.log(rslider);
          }
          setSliderList(rslider);

        } else {
          setSliderList([]);
        }
    }).catch((error) => {
      setSliderList([]);
    });
  }
  return (
    <div>
      <div >
        <div className='container py-2'>
        {categoryList?(<RoundCard categoryList={categoryList}/>):(<div className="py-5">loading....</div>)}
        </div>
      </div>
      {
        sliderList?(<>
        {sliderList.length?(
          <div className="border-bottom "><SecSlider sliderUrl={sliderList[0]} responsive={sliderRsponsive} type="home" height='270px' autoplay={true}/> </div>):''
        }
        </>):('')
      }
      {categoryList?categoryList.map((row)=>{
        // console.log(row,'row');
        if(row.type=='dynamic-page'){
        return (
          <div className=" container border-top pt-3 pb-5">
            <RecentListByCategories sectionInfo={{title:row.name,  description:row.description, url: row.url, row:row}} />
          </div>
        )
      }
      }):(<div className="py-5"></div>)}
      {/* <div className=''>
        <SecOne/>
      </div> */}
    </div>
  )
}
