import React from 'react';
import {SecSinglePage} from "../section";

export default function ProductDetails(props) {
    console.log(props);
    return (
        <div>
            <SecSinglePage  getId={props.match.params.id} getType={props.match.params.type}  />
        </div>
    )
}
