import React, { useState, useEffect } from "react";
import { getSession } from "./components/services/AuthServices";
import { Route, Switch } from "react-router-dom";
import { getRequest, postRequest } from './components/services/ApiServices';
import { UserLayout, AdminLayout, MainLayout, Layout } from './components/layout';
import { useSelector } from 'react-redux';
import './index.css'

import {Home, Contact, About, Guide, ProductListDetails, ProductDetails, City, AddCity, State, AddState, AddCountry, Country } from './components/pages';
import {AdminDashboard,  AdminContact,ProductList,AdminCategory,AdminSubCategory, BannerList} from './components/pages/admin';
import { SecImageView } from "./components/pages/section";
import {UserDashboard} from './components/pages/user';
export default function Routes() {
  const loading = useSelector((state) => state.LoadReducer);
  const [categoryList, setCategoryList] = useState();
  console.log(loading);
  useEffect(() => {
    getCategoryList();
  }, []);
  
  const getCategoryList = () => { 
    getRequest(`${process.env.REACT_APP_API_URL}category/site/category/getAllByActiveWithSubCat`)
    .then((res) => {
        console.log(res,'cat')
        if (res.status == 200) {
          setCategoryList(res.data.data);
        } else {
          setCategoryList([]);
        }
    }).catch((error) => {
      setCategoryList([]);
    });
  }
  return (
    <React.Fragment>
      <Switch>
        <MainLayout path="/home" key={`home`} component={Home} pagename={"Home"} />
        <MainLayout path="/contact-us" key={`contact-us`} component={Contact} pagename={"Contact"} />
        <MainLayout path="/about-us" key={`about-us`} component={About} pagename={"About"} />
        <MainLayout path="/user-guide" key={`user-guide`} component={Guide} pagename={"Guide"} />
        {
          categoryList?(
            categoryList.map((row)=>{
             return (<MainLayout path={`/product-list/${row.id}/:cat/:subCat`} component={ProductListDetails} key={`product-${row.url}`} pagename={`product-${row.url}`} />)
            })
          ):''
        }
        <MainLayout key={`product-details`} path='/product-details/:type/:id' component={ProductDetails} pagename={"product-details"}/>

        {/* user */}
        <UserLayout path="/user/dashboard" component={UserDashboard} pagename={""} />


        {/* admin */}
        <AdminLayout path="/admin/dashboard" component={AdminDashboard} pagename={""} />
        <AdminLayout path="/admin/category" component={AdminCategory} pagename={"Category"} />
        <AdminLayout path="/admin/sub-category" component={AdminSubCategory} pagename={"sub-category"} />
        
        <AdminLayout path="/admin/contact" component={AdminContact} pagename={"Contact"} />
        <AdminLayout path="/admin/products" component={ProductList} pagename={"Products"}/>
        <AdminLayout path="/admin/banners" component={BannerList} pagename={"banner"}/>
        <MainLayout path="/" component={Home} pagename={"Home"} />

      </Switch>
    </React.Fragment>
  );
}
