import React from 'react';
import './secSlider.css';
import { Carousel } from 'antd';

const SecSlider = (props) => {
  const { sliderUrl ,responsive, type ,height,autoplay} = props;
  console.log(sliderUrl);
  return (
    <div className="col-12 px-0"  >
      <Carousel autoplay={autoplay} draggable={true} height={height}>
        {sliderUrl.images?.map((row, index)=>{
          console.log(row,'sss');
          return ( 
            <div className="w-100" key={`secSlider${index}`} >
              <div style={{ "backgroundImage":"url("+process.env.REACT_APP_IMG_URL+ "bannerlist/"+ row+")", "height":height, "backgroundPosition": "center", "backgroundSize":"cover"}}></div>
            </div>
          )
        })}
      </Carousel>
    </div>
  );
}

export default SecSlider;
