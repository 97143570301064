import React from 'react'
import './contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF , } from '@fortawesome/free-brands-svg-icons';
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub} from "@fortawesome/free-brands-svg-icons";
export default function Contactdata() {
    return (
        <div className="h-100">
                <div class="contact_localtion h-100">
						<h4>Contact Us</h4>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In gravida quis libero eleifend ornare. habitasse platea dictumst.</p>
						<div class="content_list">
							<h5>Address</h5>
							<p>2301 Ravenswood Rd Madison, <br/>WI 53711</p>
						</div>
						<div class="content_list">
							<h5>Phone</h5>
							<p>(315) 905-2321</p>
						</div>
						<div class="content_list">
							<h5>Mail</h5>
							<p>info@findhouse.com</p>
						</div>
						<div class="content_list">
							<h5>Skype</h5>
							<p>findhouse.com</p>
						</div>
						<h5>Follow Us</h5>
						<ul class="contact_form_social_area">
							<li class="list-inline-item"><a href="#">
								<FontAwesomeIcon icon={faFacebookF} /></a></li>
							<li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a></li>
							<li class="list-inline-item"><a href="#"><i class="fab fa-linkedin"></i></a></li>
							<li class="list-inline-item"><a href="#"><i class="fab fa-google"></i></a></li>
							{/* <li class="list-inline-item"><a href="#"><i class="fa fa-pinterest"></i></a></li> */}
						</ul>
					</div>
        </div>
    )
}
