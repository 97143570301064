import { React, useEffect, useState } from "react";
import { Modal, Button, Image, Tabs } from "antd";
import ReactDOM from 'react-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import loginImg from "../images/login.jpg";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { setSession,getUserData } from '../../../services/AuthServices';
import "./login.css";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { UserOutlined } from "@ant-design/icons";
import Register from "../secRegister/Register";
import {showSuccess,showError} from "../../../services/ToasterServices";
import { postRequest } from "../../../services/ApiServices";
import { user } from "../../../model/user";
import { useHistory } from 'react-router-dom';
import { appId } from "../../../../appIds";
const Login = (props) => {
  const history = useHistory();
  const { TabPane } = Tabs;
  function callback(key) {
    setTab(key)
    console.log(key);
  }
  useEffect(() => {
  }, [])
  const [loginUserObj, setLoginUserObj] = useState({email_id:'',password:''});
  const [tab, setTab] = useState('1');
  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setLoginUserObj({...loginUserObj, [e.target.name]:e.target.value})
  }
  const loginUser = () => {
      if(loginUserObj.email_id && loginUserObj.password){
        postRequest(`${process.env.REACT_APP_API_URL}user/auth/login`, loginUserObj)
            .then((res) => {
              console.log(res,'res');
                if(res.data.msg == "success"){
                    var resData = res.data.data;
                    setSession({...user,...resData});
                    if(resData.u_type == 'A')
                    {
                      history.push("/admin/dashboard")
                    }else{
                      history.push("/user/dashboard")

                    }
                }else{
                showError(res.data.msg);
                }
            })
            .catch((error) => {
              showError('try again later');
            });
      }else{
        alert("User name or email and password is required");
      }
  }

 const responseFacebook = (response)=> {
    console.log(response,"facebook")
    setSession({...user,name:response.name,email_id:response.email,token:response.accessToken,u_type:'user'});
    props.getUserData();
    props.setIsModalVisible(false);
    history.push("/home")
  }

  const responseGoogle = (response) => {
    console.log(response,"google");
    setSession({...user,name:response.profileObj.name,email_id:response.profileObj.email,token:response.tokenId,u_type:'user'})
    props.getUserData();
    props.setIsModalVisible(false);
    history.push("/home")
  }

  const facebookLogin = (
    <FacebookLogin
    appId={process.env.REACT_APP_FACEBOOK_APPID}
    autoLoad={false} 
    // autoLoad
    fields="name,email,picture"
    // onClick={componentClicked}
    callback={responseFacebook}
    render={renderProps => ( 
      <button className="btn fbbtn" onClick={renderProps.onClick}>
        <div className="row">
          <div className="col-2">
            <i
              class="fab fa-facebook-f"
              aria-hidden="true"
              style={{ alignItems: "flex-start" }}
            />
          </div>
          <a  className='fbhrefcolor pl-5 ml-4'><div className="col-10">Login with facebook</div></a>
        </div>
      </button>
      )} />
  )

  const googleLogin =(
    <GoogleLogin
    clientId={process.env.REACT_APP_GOOGLE_APPID}
    render={renderProps => (
      <button className="btn gbbtn" onClick={renderProps.onClick}>
      <div className="row ">
        <div className="col-2">
          <i
            class="fab fa-google"
            aria-hidden="true"
            style={{ alignItems: "flex-start" }}
          />
        </div>
        <a  className='gbhrefcolor pl-5 ml-4' target='blank'> <div className="col-10">Login with Google</div></a>
      </div>
    </button>)}
  buttonText="Login"
  onSuccess={responseGoogle}
  onFailure={responseGoogle}
  cookiePolicy={'single_host_origin'}
/>
  ) 
  

  return (
    <div>
     
      <Modal
      
               visible={props.isModalVisible}
        // onOk={props.handleOk}
         onCancel={props.handleCancel}
        footer={null}
        closable={true}
        height={500}
        width={798}
        
      >
        <Tabs
        className='pt-4'
          // defaultActiveKey="1"
          activeKey={tab}
          onChange={callback}
          type="card"
          style={{ width: "100%" }}
        >
          <TabPane
            key="1"
            tab={
              <div
                className="p-3"
                style={{ width: "340px", height: "50px", textAlign: "center",fontSize:'18px',color:"#000" }}
              >
                Login
              </div>
            }
          >
            <div className="row">
              
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                {/* <h5 style={{color:'green'}}>Login</h5> */}
                <div className="row p-3">
                  {" "}
                  <div class="form-group has-search" style={{ width: "100%" }}>
                    <span class="fa fa-user form-control-feedback pt-2"></span>
                    <input
                      type="text"
                      class="form-control"
                      name="email_id"
                      placeholder="Mail"
                      style={{ borderRadius: "10px", height: "50px" }}
                      value ={loginUserObj.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row pt-2 pl-3 pr-3">
                  <div class="form-group has-search" style={{ width: "100%" }}>
                    <span class="fa fa-lock form-control-feedback pt-2"></span>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      name="password"
                      value ={loginUserObj.password}
                      onChange={handleChange}
                      style={{ borderRadius: "10px", height: "50px" }}
                    />
                  </div>
                </div>
                <div className="row p-3">
                  <button className="btn logbtn" onClick={loginUser}>Log in</button>
                </div>
                <div className="pr-3 pb-2 pt-2">
                  <span className="hline">
                    <font style={{ color: "green" }}>Or</font>
                  </span>
                </div>
                <div className="row p-2">
               {facebookLogin}
                </div>
                <div className="row p-2">
                  {" "}
                {googleLogin}
                </div>
                {/* <div className="row p-2">
                  <div className="col-6 ">
                    <Checkbox className="pr-2 fs-14" />
                    Remember me
                  </div>
                  <div className="col-6">
                    <a
                      class="btn-fpswd float-right"
                      href="#"
                      style={{ color: "#8b91dd" }}
                    >
                      Lost your password?
                    </a>
                  </div>
                </div> */}
                <div className="d-flex justify-content-center">
                  <div className="row pl-4 fs-14">
                    Don't have an account?{" "}
                    <span style={{ color: "red",cursor:'pointer' }} onClick={()=>{setTab('2')}}>Register</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Image src={loginImg}></Image>
              </div>
            </div>
          </TabPane>
          <TabPane
            key="2"
            tab={<div
                className="p-3"
                style={{ width: "340px", height: "50px", textAlign: "center",fontSize:'18px',color:"#000" }}
              >
                Register
              </div>}
          >
            <Register setTab={setTab} facebookLogin={facebookLogin} googleLogin={googleLogin}/>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default Login;
