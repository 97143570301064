import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import { AddRoomType, Room, Floor, RoomStatus, ProofType, RateCard, ServiceType, City, State, Country, RoomTypeList } from '../pages';
import { Header, Footer, Breadcrumb } from '../shared';
import { useHistory } from 'react-router-dom';
import { Layout, Space, Tabs } from 'antd';
import {getSession} from '../services/AuthServices';
const { Content, Sider } = Layout;

export default function AdminLayout(props) {
    const { TabPane } = Tabs;
    function callback(key) {
      console.log(key);
    }
    const checkInForm = (data) => {
      setShowTab(Number(showTab + 1));
      console.log(showTab);
    }
    const history = useHistory();
    const { component: Component, pagename: pagename, ...rest } = props;
    const { innerWidth: width, innerHeight: height } = window;
    const outHeight = 185;
    const [showTab, setShowTab] = useState(1);
    return (
        <React.Fragment>
            <Header />
            <Layout>
                <Content className="px-5">
                    <Breadcrumb pagename={pagename} />
                </Content>
                <Layout className="mx-5 mb-5" style={{ minHeight: height - outHeight + "px" }}>
                    <Sider className="bg-white p-3 " >

                        <div className="border-right h-100 pl-4 pt-5 pb-5">
                            <Space size='large' direction="vertical" >
                                <NavLink to="/admin/dashboard" class=" text-dark pt-3 pb-3" activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}>Dashboard</NavLink>
                                {/* <NavLink to="/admin/homecare" class="text-dark" activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}>Home Care</NavLink>
                                 <button class="btn btn-link text-dark  " onClick={() => {
                                    history.push("/admin/medicines")
                                }}>Medicines</button> <button class="btn btn-link  text-dark " onClick={() => {
                                    history.push("/admin/wellness")
                                }}>Wellness</button> */}
                                 <NavLink to="/admin/contact" class="text-dark pt-3 pb-3 " activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}
                                >Contact Us</NavLink>
                                {/* <NavLink to="/admin/menu" class="text-dark  ">Menu</NavLink> */}
                                 <NavLink to="/admin/category" class="text-dark pt-3 pb-3 " activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}>Category</NavLink>
                                 <NavLink to="/admin/sub-category" class="text-dark pt-3 pb-3 " activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}>Sub-Category</NavLink>
                                 <NavLink to="/admin/products" class="text-dark pt-3 pb-3 " activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}>Products</NavLink>
                                 <NavLink to="/admin/banners" class="text-dark pt-3 pb-3 " activeClassName="active-header" style={{color: 'inherit',textDecoration:'none'}}>Banners</NavLink>
                            </Space>
                        </div>
                    </Sider>
                    <Content className="p-3 bg-white">
                        <Route {...rest} render={(props) => { 
                            // return (<Component {...props} />) 
                            return getSession('u_type') == 'A' ? <Component {...props} /> :  <Redirect to="/homecare"/>
                    }} />
                    </Content>
                </Layout>
            </Layout>
            <Footer />
        </React.Fragment>
    )
}
