import React, { useState ,useEffect} from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';
import { Home } from '../pages';
import { Header, Footer, Breadcrumb} from '../shared';
import { Layout } from 'antd';
import {getSession} from '../services/AuthServices';
const { Content } = Layout;
export default function UserLayout(props) {
    const { component: Component, pagename:pagename, ...rest} = props;
    const { innerWidth: width, innerHeight: height } = window;
    const outHeight = 185;
    return (
        <React.Fragment>
            <Header />
            <Layout className={`user-layout page-${pagename}`}>
                <Content className="px-5">
                    <Breadcrumb pagename={pagename} />
                </Content>
                <Content className="mx-5 bg-white" style={{minHeight:height - outHeight +"px"}}>
                <Route {...rest} render={(props) =>{ 
                    // console.log(getSession('name'),'userlayout');
                     return getSession('u_type') == 'user' ? <Component {...props}/> :  <Redirect to="/home"/>
                    }} />
                </Content>
            </Layout>
            <Footer />
        </React.Fragment>
    )
}
