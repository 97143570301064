import React, { useState, useEffect } from 'react';
import { ThumbSlider } from '../index';
import { useHistory } from 'react-router-dom';
import { postRequest, getRequest } from "../../../services/ApiServices";
export default function RecentListByCategories( props ) {
  const history = useHistory();
  const { sectionInfo } = props;
  const thubResponsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1200 },items: 5 },
    desktop: { breakpoint: { max: 1199, min: 992 }, items: 4,
    partialVisibilityGutter: 30 },
    tablet: { breakpoint: { max: 991, min: 464 }, items: 2 ,
    partialVisibilityGutter: 30},
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };
  const [recentListByCat, setRecentListByCat] = useState();

  useEffect(() => {
    console.log(sectionInfo,'sectionInfo');
      onGetRecentListByCat({cat_url:sectionInfo.url});
  }, []);

  const onGetRecentListByCat = (data) => {
    postRequest(`${process.env.REACT_APP_API_URL}productlist/site/productlist/getAllProductsBycatWithActivelimit`, data)
    .then((res) => {
      // console.log(res)
      if (res.status == 200) {
        setRecentListByCat(res.data.data);
      } else {
        setRecentListByCat([]);  
      }
    }).catch((error) => {
      setRecentListByCat([]);
    });
  }
      
  const onNavigatePage = (row) =>{
    history.push(`/product-list/${row.id}/${row.url}/${row.sub_cat_url}`)
  }

  return (
      <div >
        <div className="py-2">
          <div class="main-title ">
            <a  onClick={()=>{onNavigatePage(sectionInfo.row)}} style={{color:'#000',textDecoration:'none'}}> <h2 className="fw-bold fs-30">{sectionInfo.title}</h2></a>
            <div className="d-flex justify-content-between">
              <p className="mb-2">{sectionInfo.description}</p>
              <a  onClick={()=>{onNavigatePage(sectionInfo.row)}}>View All <span class="flaticon-next"></span></a>
            </div>
          </div> 
          {
            recentListByCat?(<ThumbSlider responsive={thubResponsive} data={recentListByCat} type={sectionInfo.url}/>):(<div>Loading...</div>)
          }
          
        </div>
      </div>
  )
}
