import axios from 'axios';
import { getSession } from './AuthServices';

function getHeaders(token) {
  console.log(token);
  return {
    Authorization: `Bearer ${token}`,
  };
}

export const getRequest = async (url) => {
    const token = getSession("token");
console.log(token,'rttt');
  if (token == undefined || token == null) {
    return await axios.get(url);
  } else {
    const options = {
      headers: getHeaders(token),
    };
    return await axios.get(url, options);
  }
};

export const postRequest = (url, data) => {
  const token = getSession("token");

  if (token === undefined || token === null) {
    return axios.post(url, data);
  } else {
    const options = {
      headers: getHeaders(token),
    };
    return axios.post(url, data, options);
  }
};

export const putRequest = (url, data) => {
  const token = getSession("token");

  if (token == undefined || token == null) {
    return axios.put(url, data);
  } else {
    const options = {
      headers: getHeaders(token),
    };
    return axios.put(url, data, options);
  }
};

export const deleteRequest = (url) => {
  const token = getSession("token");

  if (token == undefined || token == null) {
    return axios.delete(url);
  } else {
    const options = {
      headers: getHeaders(token),
    };
    return axios.delete(url, options);
  }
};

