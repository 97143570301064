import React, { useEffect, useState } from 'react';
import { postRequest } from '../../../services/ApiServices';
import { useHistory } from "react-router-dom";


const SecSinglePage = ({getId,getType}) => {
    const [data, setData] = useState();
    const [mainImage, setMainImage] = useState();   
    const history = useHistory();

    const getDataList = () => {
    
        postRequest(
          `${process.env.REACT_APP_API_URL}productlist/site/productlist/getProductByIdwithActive`,
          { id: getId }
        )
          .then((res) => {
            // console.log(res)
            if (res.status == 200) {
              let listInfo = res.data.data[0];
              let imageJsonList = listInfo.image ? JSON.parse(listInfo.image) : [];
              console.log(listInfo.name, "name", imageJsonList, "image");
              let obj = { ...listInfo, image: imageJsonList };
              console.log(obj,"obj");
              setMainImage(imageJsonList[0])
              setData(obj);
            } else {
              setData();
            }
          })
          .catch((error) => {
            setData();
          });
      };

useEffect(() => {
    getDataList();
}, []);
    return (
        <div>
            <div className='row mx-0'>
                <div className='col-md-6 px-0' >
                    <img src={`${process.env.REACT_APP_IMG_URL}productlist/${mainImage}`} alt={data?.name} width='100%'/>
                </div>
                <div className='col-md-6 '>
                    <div className='pt-3 pb-3' style={{display:'flex',flexWrap:'wrap',height:'100%',flexDirection:'column', justifyContent:'space-between'}}>
                    <div >
                        <div className='d-flex'>
                        <img src={`${process.env.REACT_APP_IMG_URL}subcategory/${data?.sub_category_image}`} alt={data?.subategory} width='40px' height='40px' className='rounded-circle border border-dark'/>
                        <h4 className='ml-2 align-self-center '>
                            {data?.sub_category}
                        </h4>
                        </div>
                    <h1 className='pt-2'><strong>
                        {data?.name}
                        </strong>
                    </h1>
                    <p className='pr-4'>
                        {data?.description}
                    </p>
                    </div>
                    <div className='d-flex' style={{flexWrap:'wrap'}}>
                        {data?.image.map((e)=>(
                            <img src={`${process.env.REACT_APP_IMG_URL}productlist/${e}`} className='p-1 cursor-pointer' alt={data?.name} width='100px' onClick={()=>setMainImage(e)}/>
                            ))}
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button style={{backgroundColor:"#FF5A5F", width:'50%'}} className="btn text-white " onClick={()=>history.push(`/contact-us`)}   >Ask a Question</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecSinglePage;