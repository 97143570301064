import React from 'react';
import { ThumbBoxTwo } from '../index';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './thumbSlider.css'

export default function ThumbSlider(props) {
    const {data,type} = props;
    console.log(data.length);
    return (
        <div className="w-100 cus-main-react-multi-carousel-track">
            {
                data.length ?
            (<Carousel style={{width:'100%'}} swipeable sliderClass="cus-react-multi-carousel-track" responsive={props.responsive}>
                { 
                    data.map((row, index)=>{
                        return (
                            <div>
                            <ThumbBoxTwo thumbData= {row} type={type} />                                
                            </div>
                        )
                    }) 
                }
            </Carousel>)
            :"No Records Found"}
        </div>
    )
}
