export const user = {
    id: "",
    name: "", 
    token : "",
    status : "",
    u_type: "",
    email_id : "",
    password: "",
    created_on: "",
    image: ""
}