import React, { useEffect, useState } from 'react'
import { Image, Layout, Menu,Dropdown } from 'antd';
import { setSession } from '../../services/AuthServices';
import { getRequest, postRequest } from '../../services/ApiServices';
import { NavLink, useHistory } from 'react-router-dom';
import { getSession, sessionDestroy } from "../../services/AuthServices";
import { CloseOutlined, LogoutOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons';
import { Login } from '../../pages/section';
import './header.css'
import { user } from "../../model/user";
const { Header } = Layout;

export default function HeaderNav() {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState();
  const userMenu = (
      <Menu>
        <Menu.Item key="0">
          <div  onClick={()=>{(getSession('u_type')=='A')?(history.push('/admin/dashboard')):(history.push('/user/dashboard'))}}><UserOutlined  /> Dashboard</div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1">
          <div onClick={()=>{setSession({...user});history.push('/home');getUserData()}}><LogoutOutlined/> Logout</div>
        </Menu.Item>
      </Menu>
    );
  const [userType, setUserType] = useState();
  const [userData,setUserData] = useState(null);
  const [menuPopupStatus,setMenuPopupStatus] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randamText, setRandamText] = useState(0)
  useEffect(()=>{
    setUserType(getSession('u_type'));
    getUserData();
    getCategoryList();
  }, []);
  const getUserData = () =>{
      setUserData({name:getSession('name'),
      email:getSession('email_id'),
      token:getSession('token'),
    });
  }
  const getCategoryList = () => { 
    getRequest(`${process.env.REACT_APP_API_URL}category/site/category/getAllByActiveWithSubCat`)
    .then((res) => {
        console.log(res,'cat')
        if (res.status == 200) {
          setCategoryList(res.data.data);
        } else {
          setCategoryList([]);
        }
    }).catch((error) => {
      setCategoryList([]);
    });
  }
  const showModal = () => {
      setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onMenuPopup = () =>{
    if(menuPopupStatus == 'close' || menuPopupStatus == '')setMenuPopupStatus('open'); 
    else setMenuPopupStatus('close');
  }
  return (
      <Layout className="layout header-main" >
          <Header style={{ backgroundColor:'#fff' }}  >
            <div className="container">
              <div className="d-flex justify-content-between "  >
                <div className="d-inline-block pt-md-0 pr-md-3 align-self-center" style={{cursor:'pointer'}} onClick={() => {  history.push("/home") }}>
                  <div className="d-flex">
                    <Image width="26px" src="/header-logo2.png" preview={false}  />
                    <h2 className="ml-2 align-self-center mb-0 fs-30 fw-bold">RURU</h2></div>
                  </div>
                <div className="d-inline-block d-md-none" onClick={onMenuPopup} ><MenuFoldOutlined /> </div>
                {categoryList?
                (<div className={menuPopupStatus=='close'?('d-none d-md-inline-block'):(menuPopupStatus=='open'?'d-inline-block menu-popup-open':'d-none d-md-inline-block')}>
                  <div className={menuPopupStatus?'d-flex flex-column':'d-flex'}>
                    {menuPopupStatus?(<div className="menu-popup-close  " onClick={onMenuPopup}><CloseOutlined /></div>):('')}
                    {categoryList.map((row)=>{
                    return <>{(row.type=='dynamic-page')?(<NavLink to={`/product-list/${row.id}/${row.url}/${row.sub_cat_url}`} activeClassName="active-header" className="pr-3 pl-3 " 
                    style={{color: 'inherit',textDecoration:'none'}}>{row.name}</NavLink>):(<NavLink to={`/${row.url}`} activeClassName="active-header" className="pr-3 pl-3" style={{color: 'inherit',textDecoration:'none'}} rel="noopener noreferrer">{row.name}</NavLink>)}</>
                    })}
                    {userData&&userData.token&&(userData.name||userData.token!='undefined')?
                    <Dropdown overlay={userMenu} trigger={['click']}>
                      <a className="pr-3 pl-3 " onClick={e => e.preventDefault()}>
                      <UserOutlined /><span className='ml-1' style={{}}>{userData.name}</span>
                      </a>
                    </Dropdown>:<div className="pr-3 pl-3 " style={{color: 'inherit',textDecoration:'none'}} onClick={() => {history.push("/login")},showModal}><UserOutlined /><span className='ml-1' style={{cursor:'pointer'}}>Login/Register</span></div>}
                    <button className="pr-3 pl-3 align-self-center btn btn-dark   " onClick={() => { history.push("/contact-us") }} style={{borderRadius:'50px',width:'130px'}}>Contact Us</button>
                  </div>
                </div>):''}
              </div>
            </div>
          </Header>
          <Login isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} handleOk={handleOk} handleCancel={handleCancel} getUserData={getUserData}/>
      </Layout>
  )
}
