import React, { useState, useEffect } from 'react';
import Search from './search';
import { postRequest, getRequest } from "../../services/ApiServices";
import { useHistory, useLocation } from 'react-router-dom';
import SecCardTwoPage from '../section/pagination/secCardTwoPage';
import { SecImagesRound, SecSlider } from '../section';
export default function ProductListDetails(props) {
    const history = useHistory();
    // const urlSearch = useLocation().search;
    // const subCatVal = new URLSearchParams(urlSearch).get('subCat');
    const [productListDetails, setProductListDetails] = useState();
    const [subCategoryDetails, setSubCategoryDetails] = useState();
    const [sliderList, setSliderList] = useState();
    const [catType, setCatType] = useState(props.match.params.cat);
    const [subCatType, setSubCatType] = useState(props.match.params.subCat);
    useEffect(() => {
        getAllSubCatByCat(props.match.params.cat);
        getAllProductByCatAndSubCat(props.match.params.cat,props.match.params.subCat);
        getBannerList({cat_url:props.match.params.cat, sub_cat_url: props.match.params.subCat })
    }, []);
    const dataSort = (sort)=>{ }
    
  const getBannerList = (data) => { 
    postRequest(`${process.env.REACT_APP_API_URL}bannerlist/site/bl/getAllByActiveWithCat`,data )
    .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          let rslider = [];
          if(res.data.data.length){
            rslider = res.data.data;
            console.log(rslider);
            rslider[0].images = JSON.parse(rslider[0].images);
            console.log(rslider);
          }
          setSliderList(rslider);

        } else {
          setSliderList([]);
        }
    }).catch((error) => {
      setSliderList([]);
    });
  }
    const getAllSubCatByCat = (cat_url) => {
        postRequest(`${process.env.REACT_APP_API_URL}subcategory/site/subcat/getAllByCategoryUrlWithActive`,{url:catType})
        .then((res) => {
            console.log(res)
            if (res.status == 200) {
                setSubCategoryDetails(res.data.data);
            } else {
                setSubCategoryDetails([]);
            }
        }).catch((error) => {
            setSubCategoryDetails([]);
        });
    }
    const getAllProductByCatAndSubCat = (cat_url, sub_cat_url) => {
        postRequest(`${process.env.REACT_APP_API_URL}productlist/site/productlist/getallproductsbysubcatwithactive`,{cat_url:cat_url,sub_cat_url:sub_cat_url})
        .then((res) => {
            console.log(res);
            if (res.status == 200) {
                setProductListDetails(res.data.data);
            } else {
                setProductListDetails([]);
            }
        }).catch((error) => {
            setProductListDetails([]);
        });
    }
    const [filter, setFilter] = useState();
    const search = event => {
        setFilter(event.target.value);
    };
    // const lowercasedFilter = filter?.toLowerCase();
    // const filteredData = homeCareDetails?.filter(item => {
    //     return  typeof item.name === "string" && item.name.toLowerCase().includes(lowercasedFilter) 
    // });
    const sliderUrl = {image:[
        {src: process.env.REACT_APP_PUBLIC_URL+"assets/images/slider/b10.jpg", alt:"slider1"},
        {src:process.env.REACT_APP_PUBLIC_URL+"assets/images/slider/b9.jpg", alt:"slider2"},
        {src:process.env.REACT_APP_PUBLIC_URL+"assets/images/slider/hc2.jpg", alt:"slider3"},
        {src:process.env.REACT_APP_PUBLIC_URL+"assets/images/slider/hc3.jpg", alt:"slider4"}
    ]}
    const sliderRsponsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 2},
        desktop: {breakpoint: { max: 3000, min: 1024 }, items: 2},
        tablet: {breakpoint: { max: 1024, min: 464 }, items: 2},
        mobile: { breakpoint: { max: 4000, min: 0 }, items: 1}
    };
    const navicationSubMenu = (e) =>{
        setSubCatType(e.url);
        getAllProductByCatAndSubCat(catType,e.url);
        getBannerList({cat_url:catType, sub_cat_url: e.url })
        // history.push(`/product-list/${catType}/${e.url}`);
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 breadcrumb_content" >
                    <ol class="breadcrumb my-0 pt-1" style={{backgroundColor:'#f7f7f7'}}>
                        <li class="breadcrumb-item fs-14" ><a onClick={() => { history.push("/home")}}>Home</a></li>
                        <li class="breadcrumb-item fs-14 active text-thm" aria-current="page">HomeCare</li>
                    </ol>
                </div>
            </div>
            {subCategoryDetails?(<SecImagesRound catType={catType} subCatType={subCatType} onNavicationSubMenu = {navicationSubMenu} onSubCategoryDetails={subCategoryDetails} />):''}
            {
            sliderList?(<>
            {sliderList.length?(<div className="row mx-0 py-3"><SecSlider sliderUrl={sliderList[0]} responsive={sliderRsponsive} height='180px' type="product" autoplay={true}/> </div>):''}
            </>):('')
            }
            {/* <div className='row mx-0 py-3'>
                <SecSlider sliderUrl={sliderUrl} responsive={responsive} height='180px' type="home" autoplay={true}/>
            </div> */}
            <h2 class="breadcrumb_title pt-2 mb-2 fs-22 fw-bold">List of home care services</h2>
            <p className='fs-16 col-10 px-0 mb-3 '>Home care includes any professional support services that allow a person to live safely in their home. In-home care services can help someone who is aging and needs assistance to live independently.</p>
            <div className="row">
                <div className="col-12 ">
                   {/* search component */}
                   {productListDetails?<Search  dataSort={dataSort} search={search} filter={filter} filteredData={productListDetails.length} totalLength={productListDetails.length}/>:''}
                </div>
            </div>
            {productListDetails?<SecCardTwoPage data={productListDetails} type={catType}/>:''}
        </div>
    )
}
