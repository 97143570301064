import React from 'react';
import moment from 'moment';
import {ThumbBoxThree} from '../index';


const PostTheme = (props) => {
    const {posts,type} = props;
    return (
        <div className="container">
               <div className="row pb-5">
                {posts.map(post=>(
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 py-3">
                    <ThumbBoxThree serviceObj = {post} type = {type} />
                </div>
                ))}
            </div>
        </div>
    );
}

export default PostTheme;