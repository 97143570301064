import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login, Register } from '../pages';
import { Header, Footer, Breadcrumb } from '../shared';
import { Layout } from 'antd';
const { Content } = Layout;
export default function MainLayout(props) {
    const { component: Component, ...rest } = props;
    const { innerWidth: width, innerHeight: height } = window;
    const outHeight = 0;
    return (
        <React.Fragment>
            <Header />
            <Layout >
                {/* <Content className="px-5">
                    <Breadcrumb />
                </Content> */}
                <Content className="bg-color" >
                    <div className="w-100 ">
                        <Route {...rest} render={(props) => { return (<Component {...props} />) }} />
                    </div>
                </Content>
            </Layout>
            <Footer />
        </React.Fragment>
    )
}
