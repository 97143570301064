import React from 'react';
import { getSession } from '../../../services/AuthServices';

const dashboard = () => {
    return (
        <div className='text-center py-5'>
             <h1 className="pb-5">Welcome to dashboard {getSession('name')}</h1>
            <h6 className='pt-5 '>Existing features coming soon to new dashboards. </h6>
        </div>
    );
}

export default dashboard;
