export const setSession = (data) => {
  console.log(data);
  for (const name in data) {
    console.log(name,data[name]);
    localStorage.setItem(name, data[name]);  
    console.log(data[name])   
  }
}
export const getSession = (name) =>{
    console.log(localStorage.getItem(name));
    if(localStorage.getItem(name)){
        return localStorage.getItem(name);
    }
}

export const sessionDestroy = (name) =>{
  console.log(name);
  localStorage.removeItem(name);
  // if(localStorage.removeItem(name)){
  //     return localStorage.getItem(name);
  // }
}