import React from 'react';
import '../thumbBoxTwo/thumbBoxTwoStyles.css'
import { useHistory } from 'react-router-dom';

export default function ThumbBoxTwo(props) {
    const history = useHistory();

    const {thumbData,type} = props;

    const image = thumbData.image?JSON.parse(thumbData.image):""
    
    return (
        <div>
           
            <div class="card" style={{backgroundColor:'transparent', border:'0px solid transparent !important'}} >
                <div  className="imgParentDiv" >
                    <img onClick={()=>history.push(`/product-details/${type}/${thumbData.id}`)} src={thumbData.image?`${process.env.REACT_APP_IMG_URL}productlist/${image[0]}` : `${process.env.REACT_APP_IMG_URL}productlist/default-img.jpg`} class="cursor-pointer imageStyles" alt="..." height="200"/>
                </div>
           
            <div class="card-body">
                <p onClick={()=>history.push(`/product-details/${type}/${thumbData.id}`)} class="card-text cursor-pointer text-center">{thumbData.name}</p>
            </div>
            </div>
        </div>
    )
}
