import React, { useState } from "react";
import { Modal, Button, Image, Tabs } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import loginImg from "../images/regstr.jpg";
import './register.css'
import { postRequest } from "../../../services/ApiServices";

const Register = ({setTab, facebookLogin, googleLogin}) => {
  const [registerUserObj, setRegisterUserObj] = useState({email_id:'',name:'',password:'',confirmPassword:'',status:'Active',u_type:'user'});
  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setRegisterUserObj({...registerUserObj, [e.target.name]:e.target.value})
  }

const registerForm = (e) =>{
  if(registerUserObj.password==registerUserObj.confirmPassword){
    console.log(registerUserObj,'reg');
    e.preventDefault();

    postRequest(`${process.env.REACT_APP_API_URL}user/auth/insert`, registerUserObj)
    .then((res) => {
      console.log(res,'res');
        if(res.data.message == "success"){
          // e.preventDefault();
          console.log(res.data);
          alert('successfully register')
          setTab('1')
            // var resData = res.data.data;
            // setSession({...user,...resData});
            // history.push("/admin/dashboard")
        }
        else{
          alert("user already exists")
        }
    })
    .catch((error) => {
        // showError(error)
    });
  }else{
      e.preventDefault();
      console.log(registerUserObj);
      alert('password mismatch')
  }
}
  return (
    <div>
      <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <Image src={loginImg} height="100%"></Image>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        {/* <h5 style={{color:'green'}}>Register</h5> */}
          <form onSubmit={registerForm}>
          <div className="row pt-1 pl-3 pr-3">
                  {" "}
                  <div class="form-group has-search" style={{ width: "100%" }}>
                    <span class="fa fa-user form-control-feedback pt-2"></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      name='name'
                      style={{ borderRadius: "10px", height: "50px" }}
                      // value ={registerUserObj.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row pt-1 pl-3 pr-3">
                  <div class="form-group has-search" style={{ width: "100%" }}>
                    <span class="fa fa-envelope form-control-feedback pt-2"></span>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      style={{ borderRadius: "10px", height: "50px" }}
                      name="email_id"
                      // value ={registerUserObj.email_id}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row pt-1 pl-3 pr-3">
                  <div class="form-group has-search" style={{ width: "100%" }}>
                    <span class="fa fa-lock form-control-feedback pt-2"></span>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      style={{ borderRadius: "10px", height: "50px" }}
                      name="password"
                      // value ={loginUserObj.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row pt-1 pl-3 pr-3">
                  <div class="form-group has-search" style={{ width: "100%" }}>
                    <span class="fa fa-lock form-control-feedback pt-2"></span>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Re-enter Password"
                      style={{ borderRadius: "10px", height: "50px" }}
                      name="confirmPassword"
                      // value ={loginUserObj.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                {/* <div className="row pt-1 pl-3 pr-3">
                <select classNam='w-100' style={{width:'100%',height:'50px',borderRadius:'10px',borderColor:'#e3e3e3'}} name="cars" id="cars">
    <option value="singleuser">Single User</option>
    <option value="agent">Agent</option>
    <option value="multiuser">Multi User</option>
  </select>   
               </div> */}
         
          
          {/* <div className="row pl-3 pt-3 pb-1">
            <Checkbox className="pr-2 fs-14" />I have read and accept the Terms and
            Privacy Policy?
          </div> */}
          <div className="row p-3">
            <button type='submit' className="btn logbtn">Sign Up</button>
          </div>
          <div className="d-flex justify-content-center">
            <div className="row pl-4 ">
              Already have an account?{" "}
              <span style={{ color: "red", cursor:'pointer' }} onClick={()=>{setTab('1')}} >Log In</span>
            </div>
          </div>
          </form>
          {/* <div className="pr-3 pb-2 pt-2">
            <span className="hline">
              <font style={{ color: "green" }}>Or</font>
            </span>
          </div>
          <div className="row p-2">
            {facebookLogin}
          </div>
          <div className="row p-2">
          {" "}
            {googleLogin}
          </div>
         */}
        </div>
      </div>
    </div>
  );
};

export default Register;
