import React from 'react';
import { Link } from 'react-router-dom';
import './roundCard.css'
import { useHistory } from 'react-router-dom';

const RoundCard = ({categoryList}) => {
    const history = useHistory();
   
    const navLink=(row)=>{
        history.push(`/product-list/${row.id}/${row.url}/${row.sub_cat_url}`);
    }

    return (
        <div className="d-flex " >
            {categoryList?.map((e)=>{
                if(e.type=='dynamic-page'){
                return (
                    <div class="col-auto pl-0 pr-5" onClick={()=>navLink(e)} style={{cursor:'pointer'}}>
                        <div className='sec-round-card-frame   text-center' >
                            <div className= {'sec-round-frame'} style={{backgroundImage:`url(${process.env.REACT_APP_IMG_URL}category/${e.image})`}}>
                            </div>
                        </div>
                        <div className='text-center sub-cat-title my-0 pt-1'>
                            <span>{e.name}</span>
                        </div>
                    </div>
                    )
                }
            })}
        </div>
    );
}

export default RoundCard;
