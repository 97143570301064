import React, {useState} from 'react'
import './thumbBoxOne.css'
import { Modal, Button,Form, Input,InputNumber } from 'antd';
import {showSuccess,showError} from "../../../services/ToasterServices";
import { postRequest } from "../../../services/ApiServices";
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;

export default function ThumbBoxOne(props) {
    const {serviceObj,type} = props;
    const [form] = Form.useForm();
    const history = useHistory();
    const [image,setImage] = useState(serviceObj.image?JSON.parse(serviceObj.image):'');
    const [visible, setVisible] = useState(false);
    const onFinish = (values) => {
        let data = { type: type,service_id:serviceObj.id, ...values }
            postRequest(`${process.env.REACT_APP_API_URL}contact-us/insert`, data)
                .then((res) => {
                    if(res.data.message == "success"){
                        showSuccess('Successfully submitted.');
                        form.resetFields();
                        setVisible(false);
                    }else{
                    showError(res.data.message)
                    }
                })
                .catch((error) => {
                    showError(error)
                });
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      }
    return (
        <div>
            <div className="img-section">
                <div className="booking">
                    <a className="btn btn-dark text-white pl-md-4" onClick={() => setVisible(true)}>Booking</a>
                </div>
                <img onClick={()=>history.push(`/product-details/${type}/${serviceObj.id}`)}  style={{width:'100%',height:'400px'}}   src={serviceObj.image?`${process.env.REACT_APP_IMG_URL}${type}/${image[0]}`:`./assets/${type}/default-img.jpg`}  /> 
                <div className="image-layer text-white">
                    <div className="details">
                        <h4>{serviceObj.name}</h4>
                        <p>Home care includes any professional support services that allow a person to live safely in their home.</p>
                    </div>
                </div>
            </div>      
            <Modal title={serviceObj.name} centered visible={visible}
                onCancel={() => setVisible(false)}
                // width={1000}
                footer={[
                ]}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <Form name="basic" form={form} labelCol={{ span: 6 }}  wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={onFinish}  onFinishFailed={onFinishFailed} >
                            <Form.Item label="Username"  name="name" rules={[{ required: true, message: 'Please enter your name!' }]} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="email" label="Email" rules={[{ type: 'email',required: true, message: 'Please enter your email!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please enter your phone number!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Message" name="message" rules={[ { required: true, message: 'Please enter message!', },]}>
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit" className="btn-dark">Submit</Button>
                                <Button style={{backgroundColor:"#FF5A5F"}} type="btn text-white" className="ml-3" onClick={() => setVisible(false)} >Cancel</Button>
                            </Form.Item>
                        </Form>
                        <div><p>Note : Please fill required details</p></div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}