import React,{ useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../services/ApiServices";
import RoundCard from "../roundCard/roundCard";
import "./secImagesRound.css";
import { useHistory } from 'react-router-dom';


const SecImagesRound = (props) => {
  const { catType, subCatType, onNavicationSubMenu, onSubCategoryDetails } = props;
  const history = useHistory();
  const [active, setActive] = useState();
  const navLink=(name,subCatUrl)=>{
    onNavicationSubMenu({name:name,url :subCatUrl});
  }

  useEffect(() => { }, []);

  return (
    <div class="d-flex flex-nowrap" >
      <div class="col px-0 border-right" 
      style={{cursor:'pointer'}}>
          <div className='sec-round-card-frame   text-center' >
              <div className='sec-round-frame' style={{backgroundImage:`url(${process.env.REACT_APP_IMG_URL}category/${onSubCategoryDetails.length?onSubCategoryDetails[0].cat_image:''})`, backgroundSize:'contain'}}></div>
          </div>
          <div className='text-center sub-cat-title my-0 pt-1'>
              <span>{onSubCategoryDetails.length?onSubCategoryDetails[0].cat_name:''}</span>
          </div>
      </div>
      {onSubCategoryDetails?.map((e)=>(
      <div class="col px-0" onClick={()=>navLink(e.cat_name,e.url)} style={{cursor:'pointer'}}>
          <div className='sec-round-card-frame   text-center' >
            {/* {`${process.env.REACT_APP_IMG_URL}subcategory/${e.image}`} */}
              <div className= {subCatType==e.url?'sec-round-frame active-submenu':'sec-round-frame '} style={{backgroundImage:`url(${process.env.REACT_APP_IMG_URL}subcategory/${e.image})`}}>
              {/* <img className='rounded-circle p-1 ' src={`${process.env.REACT_APP_IMG_URL}subcategory/${e.image}`} alt='sub category' width='50%' height='50px'  /> */}

              </div>
          </div>
          <div className='text-center sub-cat-title my-0 pt-1'>
              
              <span className={(window.location.pathname=='/homecare'&& e.name==='Homecare') || (window.location.pathname=='/wellness'&& e.name === 'Wellness') || (window.location.pathname==='/medicines'&& e.name == 'Medicines') ? 'active-header ':''}  
              >{e.name}</span>
          </div>
      </div>
      ))}</div>
  );
};

export default SecImagesRound;
