import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import { store } from './components/redux/store/StoreRedux';
import { ToastContainer, toast } from 'react-toastify';
import Routes from './route';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import './App.css';
import './fontsize.min.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}> 
        <BrowserRouter>
          <Routes />
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
