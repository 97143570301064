import PostTheme from './postTheme';
import Pagination from './pagination';
import React, { useEffect, useState } from 'react';


const SecCardTwoPage = (props) => {
    const posts = props.data;  

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(9);



    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    const paginate= (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div>
            {posts.length?(<><PostTheme posts={currentPosts} type={props.type}/>
            <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} /></>):(
                <div className="w-100 py-5 text-center">
                    <h6>No records found</h6>
                </div> 
            )}
        </div>
    );
}

export default SecCardTwoPage;
