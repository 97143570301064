import {React, useState} from "react";
import { Row, Col, Typography, Form, Input, InputNumber, Button, Select } from 'antd';
import { postRequest } from "../../services/ApiServices";
import { showError, showSuccess } from '../../services/ToasterServices';

const { TextArea } = Input;

export default function Contactform() {
	const [form] = Form.useForm();
	const layout = {
		labelCol: {
		  span: 24,
		},
		wrapperCol: {
		  span: 24,
		},
	  };
	  const tailLayout = {
		wrapperCol: {
		  offset: 8,
		  span: 16,
		},
	  };
	const onFinish = (values) => {
		  let data = { created_by: 0, service_id:0, ...values }

		 postRequest(`${process.env.REACT_APP_API_URL}contact-us/insert`, data)
                .then((res) => {
                    if(res.data.message == "success"){
                        form.resetFields();
                        showSuccess('Successfully submitted.');
                    }else{
						showError(res.data.message);
                    }
                })
                .catch((error) => {
                    showError('Please try again later')
                });
		}
    return (
        <div>
        <div class="form_grid">
						<h4 class="mb5">Send Us An Email</h4>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In gravida quis libero eleifend ornare. Maecenas mattis enim at arcu feugiat, sit amet blandit nisl iaculis. Donec lacus odio, malesuada eu libero sit amet, congue aliquam leo. In hac habitasse platea dictumst.</p>
			            <Form {...layout} class="contact_form" id="contact_form" name="contact_form"  form={form} onFinish={onFinish}novalidate="novalidate">
							<div class="row">
				                <div class="col-md-6">
								<Form.Item label="Name" name="name" rules={[ { required: true, message: 'Please enter name!' }]}>
								<Input />
								</Form.Item>
				                </div>
				                <div class="col-md-6">
				                    <div class="form-group">
									<Form.Item label="Email" name="email" rules={[ { required: true, message: 'Please enter email!' }]}>
										<Input />
									</Form.Item>
				                    </div>
				                </div>
				                <div class="col-md-6">
				                    <div class="form-group">
									<Form.Item label="Phone No" name="phone" rules={[ { required: true, message: 'Please enter phone no!' }]}>
										<Input />
									</Form.Item>
				                    </div>
				                </div>
				                <div class="col-sm-12">
									<Form.Item
									label="Message"
									name="message"
									rules={[
										{
										required: true,
										message: 'Please enter message!',
										},
									]}
									>
									<TextArea rows={4} />
									</Form.Item>
									<Form.Item >
										<Button type="primary" htmlType="submit">Send Message</Button>
									</Form.Item>
				                    {/* <div class="form-group mb0">
					                    <button type="button" class="btn btn-lg btn-thm">Send Message</button>
				                    </div> */}
				                </div>
			                </div>
			            </Form>
					</div>
        </div>
    )
}
