
import React, { useState,useRef, useEffect } from 'react';
import { Button, Col, Form, Row, Select, Space, Table, Typography, Input, DatePicker, Image,Modal  } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { postRequest, getRequest } from "../../../services/ApiServices";
import {showSuccess,showError} from "../../../services/ToasterServices"
const { Title } = Typography;
const { confirm } = Modal;

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

export default function Category() {
    const [addPost, setAddPost] = useState(false);
    const [form] = Form.useForm();
    const [updateValue, setUpdateValue] = useState(null);


    const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

    const [dataSource, setDataSource] = useState();
    const [category, setCategory] = useState();
    const [previewImg, setPreviewImg] = useState();

      
      const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (text, record,index) => (
                <div>{index+1}</div>
  
          ),
        },
        {
            title: 'Image',
            dataIndex: 'image', 
            render: (text, record) => (

                <Image height="70px"  width="105px"  src={record.image?`${process.env.REACT_APP_IMG_URL}subcategory/${record.image}`:`${process.env.REACT_APP_IMG_URL}medicines/default-img.jpg`}  alt={"No image"} />


            ),
            
        },
        
        {
            title: 'Name',
            dataIndex: 'name', 
            key : 'name',
            
        },
        {
            title: 'Category',
            dataIndex: 'category', 
            key : 'category',
            
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="btn btn-dark" className="bg-color-2nd" onClick={() => {setUpdateValue(record);setAddPost(true) }}>Edit</Button>
                    <Button type="btn text-white" style={{backgroundColor:"#FF5A5F"}} className="bg-color-1st" onClick={() => { deleteRecord(record.id) }}>Delete</Button>
                </Space>
            ),
        },
      ];

      const getPostList = () => {
        getRequest(`${process.env.REACT_APP_API_URL}subcategory/admin/subcat/getAll`)
            .then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    setDataSource(res.data.data);
                    console.log(res.data.data)
                    //  data = res.data.data;
                } else {

                }
            })
            .catch((error) => {

            });
    }
    const getCategoryList = () => {
        getRequest(`${process.env.REACT_APP_API_URL}category/site/category/getAllByActive`)
            .then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    setCategory(res.data.data);
                    console.log(res.data.data)
                    //  data = res.data.data;
                } else {

                }
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        getPostList();
        getCategoryList();
    }, []);

    const deleteRecord = (e) => {
        console.log(e)
        confirm({
            title: 'Do you Want to delete?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                // axios.post(`${process.env.REACT_APP_API_URL}post/test`, [{id: e}])
                    postRequest(`${process.env.REACT_APP_API_URL}subcategory/admin/subcat/delete`, {id: e,deleted_by : 2,deleted_reason :'Test'})
                    .then((res) => {
                        if(res.data.message==="success"){
                        showSuccess(res.data.message)
                        getPostList();
                    }else{
                        showError(res.data.message)
                    }
                    })
                    .catch((error) => {
                        showError(error)
                    });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const showConfirm = () =>{
        confirm({
            title: 'Do you Want to delete image?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                let obj ={id:updateValue.id,image:updateValue.image}
                postRequest(`${process.env.REACT_APP_API_URL}subcategory/admin/subcat/delete-image`, obj)
                .then((res) => {
                    console.log(res);
                    if(res.data.message == "success" && res.status == 200){
                        setUpdateValue({...updateValue,image:''});
                        showSuccess("Image deleted successfully");
                        getPostList();
                    }else{
                        showError(res.data.message);
                    }
                })
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
      const onFinish = (values) => {
        if (updateValue != null){
            var obj = {...values,id:updateValue.id,modified_by:1,old_img_path:updateValue.image}
        }else{
          var obj = {...values,created_by:1}
        }
        console.log('Success:', {obj});
        let formData = new FormData();
        // formData.append("sampledata","hindu")
        formData.append("input_data", JSON.stringify(obj));
        console.log(image);
        if (image.length){
        formData.append(
            "uploads",
            image[0],
            image[0].name
        );}else formData.append("uploads", "");
        console.log(formData);
        if (updateValue == null) {
            // let data = { ...values,description,image: image }
            postRequest(`${process.env.REACT_APP_API_URL}subcategory/admin/subcat/insert`, formData)
                .then((res) => {
                    alert('post is added');
                    setAddPost(false)
                    form.resetFields();
                    setImage('');
                    setPreviewImg(null)
                    getPostList();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            console.log(formData);
            // let data = {...values,description,image: image }
            postRequest(`${process.env.REACT_APP_API_URL}subcategory/admin/subcat/update`, formData)
                .then((res) => {
                    console.log(res);
                    alert('post is updated');
                    setAddPost(false)
                    form.resetFields();
                    setUpdateValue(null);
                    setPreviewImg(null)
                    getPostList();
                    setImage('');
                })
        }
        
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      

      const [image, setImage] = useState('');

      const uploadImage = (event)=> {
          console.log(event)
    console.log(event.target.files);
    if (event.target.files.length != 0)
      setImage(event.target.files)
      console.log(image,"image");
      }

    const preview=(files)=> {
        // console.log(files);
        if (files.length === 0) return;
        if (files[0].size > 1000000) return;
        var reader = new FileReader();
        // this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          setPreviewImg(reader.result);
        //   console.log(reader.result)
        };
    }
    return (
        <div>
             <div className="container ">
                <div className='card'>
                    {addPost?<div className='p-4'>
                        <div>
                        <Row>
                <Col span={24}><Title level={4}>{
            updateValue ? (
              "Update "
            ) : "Add "
          }SubCategory Details</Title></Col>
            </Row>
           
                
               
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={updateValue}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}

                    >
                         <Row>
                         <Col span={20} className='p-4'>
                        
                        <Form.Item label="Name" name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        {/* <input  type="file" accept="image/*"onChange={(e)=>{uploadImage(e);preview(e.target.files)}}/>
                        {updateValue?.image && previewImg==null?<Image
                                    width={230}
                                    height={180}
                                    src={`${process.env.REACT_APP_IMG_URL}/medicines/${updateValue.image}`}
                                />:<Image
                                    width={230}
                                    height={180}
                                    src={previewImg}
                                />} */}
                                <Form.Item label="Description" name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Description!',
                                },
                            ]}
                        >
                            <Input.TextArea rows={6} />
                        </Form.Item>
                      
                        <Form.Item label="Category" name="category_id" rules={[{ required: true, message: 'Please select category!' }]}>
                            <Select>
                                {category.map((e)=>(
                                            <Select.Option value={e.id}>{e.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        
                        <Form.Item label="Url" name="url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter url!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Please select status!' }]}>
                            <Select>
                                    <Select.Option value="Active">Active</Select.Option>
                                    <Select.Option value="InActive">InActive</Select.Option>
                                    <Select.Option value="Deleted">Deleted</Select.Option>

                            </Select>
                        </Form.Item>
                   
                        <Row justify="center">
                            
                            <Col span={18} className="text-center">
                                {
                                    updateValue ? updateValue.image ? '' : <input   type="file" accept="image/*"onChange={(e)=>{uploadImage(e);preview(e.target.files)}}/> : <input   type="file" accept="image/*"onChange={(e)=>{uploadImage(e);preview(e.target.files)}}/> 
                                }
                                {updateValue?.image && previewImg==null?<Image
                                    width={230} className="mt-4"
                                    height={180}
                                    src={`${process.env.REACT_APP_IMG_URL}subcategory/${updateValue.image}`}
                                />:<Image
                                    width={230}
                                    height={180}
                                    src={previewImg} className="mt-4"
                                />}
                                 {
                                    updateValue ? updateValue.image ? <Button type="danger" className=" mt-4" onClick={showConfirm}>Delete</Button> : '' : '' 
                                }
                               </Col>
                        </Row>
                         

                        <Form.Item {...tailLayout}>
                            <Row justify="space-between" className='mt-5 text-center'> 
                                <Col>
                                    <Button type="primary" className="btn-dark bg-color-2nd" htmlType="submit"  >
                                    {
                                        updateValue ? (
                                        "Update "
                                        ) : " Add "
                                    }
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="btn text-white" style={{backgroundColor:"#FF5A5F"}} onClick={() => {
                                        // history.push("/rate-card-list");
                                        setAddPost(false); setUpdateValue(null); setPreviewImg(null);  getPostList();
                                    }} className="bg-color-2nd" >
                                        Cancel
                        </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                        </Col>
                        </Row>
                    </Form>
               
                
           

                        </div>
                        {/* <button className='btn bg-color-2nd text-white' onClick={()=>setAddPost(false)}>back</button> */}
                        </div>:
                        <div className='p-4'>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-dark text-white' onClick={()=>setAddPost(true)}>ADD</button>
                    </div>
                    <hr/>
                    <Table dataSource={dataSource} columns={columns} />
                    </div>
                    }
                </div>
            </div>
           
        </div>
    )
}
