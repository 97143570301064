
import React, { useState,useRef, useEffect } from 'react';
import { Button, Col, Form, Row, Select, Space, Table, Typography, Input, DatePicker, Image,Modal  } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { postRequest, getRequest } from "../../../services/ApiServices";
import {showSuccess,showError} from "../../../services/ToasterServices"
const { Title } = Typography;
const { confirm } = Modal;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 8,
    },
};

export default function Contact() {
 
    const [contactDetails, setContactDetails] = useState();

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },        
        {
            title: 'Name',
            dataIndex: 'name', 
            key : 'name',
            
        },
        {
            title: 'Email',
            dataIndex: 'email', 
            key : 'email',
            
        },
        {
            title: 'Phone',
            dataIndex: 'phone', 
            key : 'phone',
            
        },
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
        }
      ];

      const getContactsList = () => {
        getRequest(`${process.env.REACT_APP_API_URL}contact-us/admin/contact/getAll`)
            .then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    setContactDetails(res.data.data);
                } else {

                }
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        getContactsList();
    }, []);
    
    return (
        <div>
             <div className="container">
                <div className='card'>
                <Table dataSource={contactDetails} columns={columns} />
                </div>
            </div>
           
        </div>
    )
}
