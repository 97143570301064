import React from 'react'
import { useHistory } from 'react-router-dom';

export default function Guide() {
    const history = useHistory();

    return (
        <div>
           <div className="container">
                <div className="row">
                    <div className="col-12   breadcrumb_content">
                        <ol class="breadcrumb p-0  my-0 bg-transparent">
                            <li class="breadcrumb-item fs-14"><a onClick={() => { history.push("/home")}}>Home</a></li>
                            <li class="breadcrumb-item  fs-14 active text-thm" aria-current="page">User Guide</li>
                        </ol>
                        </div>
                        <div className=" col-12 pb-4">
                        <h2 class="breadcrumb_title  py-2">ISO Certified Home Care Services Company</h2>
                        <p>Since 2021, we’ve supported adults of all ages to maintain their independence and reside in the comfort of their homes. Covering every region of Andhra Pradesh and Telangana we’ve been the home care provider of choice to thousands of families for over 7 years. Our carers go the extra mile for our customers and get to see first-hand the peace of mind that their ongoing support provides.</p>
                        <p>Here at Eminence home healthcare, we are extremely proud of our accreditations and accolades, ensuring that we are recognized throughout the home care industry as a leader in our field providing quality care. Since our inception over 7 years ago, we have prided ourselves on providing a professional live-in care service that has become a viable alternative to residential care homes. With the support of our experienced nursing team, we are able to support those with complex conditions to live safely and comfortably at home.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
