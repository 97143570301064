import React from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
const {Footer} = Layout;

export default function FooterNav() {
    const footStyle={fontSize: '16px',lineHeight: '1.642'};
    const history = useHistory();
    return (
        <Layout >
            <Footer className='bg-white'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3'>
                            <div style={footStyle}>
                            <h4>About Site</h4>
                            <p className='text-secondary'>We’re reimagining how you buy, sell and rent. It’s now easier to get into a place you love. So let’s do this, together.</p>
                            </div>
                        </div>
                        <div className='col-sm-4 col-md-4 col-lg-2 col-xl-3 pl-xl-5 '>
                            <div style={footStyle}>
                                <h4>Quick Links</h4>
                                <ul className="list-unstyled">
                                    <li><a className="cursor-pointer" onClick={()=>{
                                        history.push('/about-us')
                                    }} className='text-secondary' >About Us</a></li>
                                    {/* <li><a href="#" className='text-secondary'>Terms &amp; Conditions</a></li> */}
                                    <li><a className="cursor-pointer"  onClick={()=>{
                                        history.push('/user-guide')
                                    }}className='text-secondary'>User’s Guide</a></li>
                                    {/* <li><a href="#" className='text-secondary'>Support Center</a></li>
                                    <li><a href="#" className='text-secondary'>Press Info</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4 col-md-4 col-lg-4 col-xl-3'>
                            <div style={footStyle}>
                            <h4>Contact Us</h4>
                            <ul className="list-unstyled">
                                <li><a href="#"className='text-secondary'>info@findhouse.com</a></li>
                                <li><a href="#"className='text-secondary'>Collins Street West, Victoria</a></li>
                                <li><a href="#"className='text-secondary'>8007, Australia.</a></li>
                                <li><a href="#"className='text-secondary'>+1 246-345-0699</a></li>
                                <li><a href="#"className='text-secondary'>+1 246-345-0695</a></li>
                            </ul>
					    </div>
                        </div>
                        <div className='col-sm-4 col-md-4 col-lg-2 col-xl-3'>
                            <div style={footStyle}>
                                <h4>Follow us</h4>
                                <ul className=" list-unstyled d-flex justify-content-between mt-3" style={{fontSize:'20px'}} >
                                    <li className="list-inline-item"><a href="#" className='text-secondary'><i className="fab fa-facebook-f"/></a></li>
                                    <li className="list-inline-item"><a href="#" className='text-secondary'><i className="fab fa-twitter"/></a></li>
                                    <li className="list-inline-item"><a href="#" className='text-secondary'><i className="fab fa-instagram"/></a></li>
                                    <li className="list-inline-item"><a href="#" className='text-secondary'><i className="fab fa-pinterest"/></a></li>
                                    <li className="list-inline-item"><a href="#" className='text-secondary'><i className="fab fa-dribbble"/></a></li>
                                    <li className="list-inline-item"><a href="#" className='text-secondary'><i className="fab fa-google"/></a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className='col-sm-4 col-md-4 col-lg-3 col-xl-3'>
                        <div style={footStyle}>
						<h4>Subscribe</h4>
						<form className="mt-3" >
						 	<div className="form-row align-items-center">
							    <div className="col-auto">
							    	<input type="email" className="form-control mb-2" style={{borderRadius: '25px',border: 'none',backgroundColor: '#f0f2f5', height:'50px',width:'270px'}} id="inlineFormInput" placeholder="Your email"/>
							    </div>
							    <div className="col-auto">
							    	<button type="submit" className="btn btn-primary mb-2  " style={{borderRadius: '50%',border: 'none',backgroundColor: '#354765',height:'50px',width:'50px'}}><i className="fa fa-angle-right" style={{fontSize:'18px'}}></i></button>
							    </div>
						  	</div>
						</form>
                        </div>
                        </div> */}
                    </div>
                </div>
                   
            </Footer>
        </Layout>
    )
}
