import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Input,
  DatePicker,
  Image,
  Modal,
} from "antd";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getSession } from "../../../services/AuthServices";
import { postRequest, getRequest } from "../../../services/ApiServices";
import { showSuccess, showError } from "../../../services/ToasterServices";
const { Title } = Typography;
const { confirm } = Modal;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 19,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export function PreviewSeletedImages(props) {
  console.log(props);
  // let files = p
  // console.log(files);
  // if (files.length === 0) return;
  // if (files[0].size > 1000000) return;
  var reader = new FileReader();
  // this.imagePath = files;
  const [imgFile, setImgFile] = useState("");
  reader.readAsDataURL(props.files);
  reader.onload = (_event) => {
    setImgFile(reader.result);
    //   setPreviewImg(reader.result);
    //   console.log(reader.result)
  };
  return (
    // <img width="100px" className="p-2 border mr-2" src={imgFile} />
    <div className=" d-inline-block">
      <Image width={100} className="border d-inline-block" src={imgFile} />
     
    </div>
  );
}

const BannerList = () => {
  const [addPost, setAddPost] = useState(false);
  const [form] = Form.useForm();
  const [updateValue, setUpdateValue] = useState(null);
  const [chooseFiles, setChooseFiles] = useState([]);
  // var filesUpload = [];
  const [deletedImage, setdeletedImage] = useState();
  const [randamVal, setRandamVal] = useState('');
  var imageArray = [];


  const [dataSource, setDataSource] = useState();
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [category, setCategory] = useState();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text, record) => (
        <Image
          height="70px"
          width="105px"
          src={
            record.images
              ? `${process.env.REACT_APP_IMG_URL}bannerlist/${
                  JSON.parse(record.images)[0]
                }`
              : `${process.env.REACT_APP_IMG_URL}productlist/default-img.jpg`
          }
          alt={"No image"}
        />
      ),
    },
    {
      title: "Category",
      dataIndex: "cname",
      key: "cname",
    },
    {
      title: "Sub Category",
      dataIndex: "scname",
      key: "scname",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="btn btn-dark"
            onClick={() => {
              setUpdateValue(record);
              setChooseFiles([]);
              setAddPost(true);
              form.setFieldsValue(record);
              getSubCategory(record.category_url);
            }}
          >
            Edit
          </Button>
          <Button
            type="btn text-white"
            style={{ backgroundColor: "#FF5A5F" }}
            onClick={() => {
              deleteRecord(record.id);
              setChooseFiles([]);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const getBannerList = () => {
    getRequest(
      `${process.env.REACT_APP_API_URL}bannerlist/admin/bl/getAll`
    )
      .then((res) => {
        console.log(res, "data");
        if (res.status == 200) {
          setDataSource(res.data.data);
          console.log(res.data.data, "data");
          //  data = res.data.data;
        } else {
        }
      })
      .catch((error) => {});
  };

  const getSubCategory = (e) => {
    console.log(e);
    postRequest(
      `${process.env.REACT_APP_API_URL}subcategory/admin/subcat/getbycategoryurl`,
      { category_url: e }
    )
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          setSubCategoryData(res.data.data);
          console.log(res.data.data, "sub category data");

          //  data = res.data.data;
        } else {
        }
      })
      .catch((error) => {});
  };
  const getSubCategoryList = () => {
    getRequest(
      `${process.env.REACT_APP_API_URL}subcategory/site/subcat/getAllByActive`
    )
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          setSubCategoryData(res.data.data);
          console.log(res.data.data, "sub category data");
          //  data = res.data.data;
        } else {
        }
      })
      .catch((error) => {});
  };

  const getCategory = () => {
    getRequest(
      `${process.env.REACT_APP_API_URL}category/site/category/getAllByActive`
    )
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          setCategory(res.data.data);
          console.log(res.data.data, "sub category data");
          //  data = res.data.data;
        } else {
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getBannerList();
    // getSubCategoryList();
    getCategory();
  }, []);
  const deleteRecord = (e) => {
    console.log(e);
    confirm({
      title: "Do you Want to delete?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        postRequest(
          `${process.env.REACT_APP_API_URL}bannerlist/admin/bl/delete`,
          { id: e, deleted_by: getSession('user_id'), deleted_reason: "" }
        )
          .then((res) => {
            if (res.status == 200) {
              getBannerList();
            } else {
            }
          })
          .catch((error) => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onFinish = (values) => {
    if (updateValue != null) {
      var obj = {
        sub_category_url:'',
        ...values,
        id: updateValue.id,
        modified_by: 1,
        old_img_path: updateValue.images,
      };
    } else {
      var obj = { ...values, created_by: getSession("user_id") };
    }
    console.log("Success:", obj );
    let formData = new FormData();
    // formData.append("sampledata","hindu")
    formData.append("input_data", JSON.stringify({ ...obj, user_id: getSession("user_id") }));
    if (chooseFiles.length) {
      chooseFiles.map((e) => {
        formData.append("uploads", e, e.name);
      });
    } else formData.append("uploads", "");
    console.log(formData);

    if (updateValue == null) {
      console.log(formData);
      postRequest(
        `${process.env.REACT_APP_API_URL}bannerlist/admin/bl/insert`,
        formData
      )
        .then((res) => {
          console.log(res,'rse');
          if(res.data.message == 'success'){
            showSuccess("Banner list added successfully");
            setAddPost(false);
            form.resetFields();
            setPreviewImg(null);
            setChooseFiles([]);
            getBannerList();
            setImage("");
          } else {
            showError(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          showError('Please try again later.');
        });
    } else {
      // let data = {...values,description,image: image }
      postRequest(
        `${process.env.REACT_APP_API_URL}bannerlist/admin/bl/update`,
        formData
      ).then((res) => {
        console.log(res);
        if(res.data.message == 'success'){
          showSuccess("Banner list updated successfully");
          setAddPost(false);
          form.resetFields();
          setUpdateValue(null);
          setPreviewImg(null);
          setChooseFiles([]);
          getBannerList();
          setImage("");
        } else {
          showError(res.data.message);
        }
      }).catch((error) => {
        console.log(error);
        showError('Please try again later.');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [image, setImage] = useState();

  const uploadImage = (event) => {
    console.log(event.target.files);
    if (event.target.files.length != 0)
    setChooseFiles([...chooseFiles,...Object.values(event.target.files)]);
    console.log(chooseFiles);
  };
  const del = (e,index) => {
    chooseFiles.splice(index,1)
    console.log(e);
    setChooseFiles(chooseFiles)
    setRandamVal( (Math.random()*10000) + 'radam' );
    console.log(chooseFiles)

  }

  const imageDelete = (e,index)=>{
    imageArray= JSON.parse(updateValue.images);
    imageArray.splice(index,1);

    postRequest(
      `${process.env.REACT_APP_API_URL}bannerlist/admin/bl/delete-image`,
      {deleteImage: `public/bannerlist/${e}`,image: JSON.stringify(imageArray),id: updateValue.id}
    ).then((res) => {
      console.log(res);
      if (res.data.message == "success" && res.status == 200) {
        setUpdateValue({ ...updateValue, images: JSON.stringify(imageArray) });
        showSuccess("Image deleted successfully");
        getBannerList();
      }
    })

  }

  const preview = (files) => {
    // console.log(files);
    if (files.length === 0) return;
    if (files[0].size > 1000000) return;
    var reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      setPreviewImg(reader.result);
      //   console.log(reader.result)
    };
  };

  const showConfirm = (e) => {
    confirm({
      title: "Do you Want to delete image?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        let obj = { id: updateValue.id, image: updateValue.image };
        postRequest(
          `${process.env.REACT_APP_API_URL}productlist/admin/productlist/sdelete-image`,
          obj
        ).then((res) => {
          console.log(res);
          if (res.data.message == "success" && res.status == 200) {
            setUpdateValue({ ...updateValue, image: "" });
            showSuccess("Image deleted successfully");
            getBannerList();
          } else {
            showError(res.data.message);
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div>
      <div className="container  ">
        <div className="card">
          {addPost ? (
            <div className="p-4">
              <div>
                <Row>
                  <Col span={24}>
                    <Title level={4}>
                      {updateValue ? "Update " : "Add "} Banner
                    </Title>
                  </Col>
                </Row>

                <Form
                  {...layout}
                  name="basic"
                  form={form}
                  initialValues={updateValue}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row>
                    <Col span={20} className="p-4">
                      <Form.Item
                        label="category"
                        name="category_url"
                        rules={[
                          {
                            required: true,
                            message: "Please select sub category!",
                          },
                        ]}
                         
                      >
                        <Select onChange={(e) => getSubCategory(e)} disabled={updateValue?true:false}>
                          {category.map((e) => (
                            <Select.Option value={e.url}>{e.name}</Select.Option>
                          ))} 
                        </Select>
                      </Form.Item>
                    {
                      subCategoryData.length?(
                    <Form.Item
                    label="Sub category"
                    name="sub_category_url"
                    rules={[
                      {
                        required: updateValue?(updateValue.type=='dynamic-page'?true:false):false,
                        message: "Please select sub category!",
                      },
                    ]}
                  >
                        <Select disabled={updateValue?true:false}>
                      {subCategoryData.map((e) => (
                        <Select.Option value={e.url}>{e.name}</Select.Option>
                      ))}
                    </Select>
                     
                  </Form.Item> ):''
                    }
                    

                      <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                          { required: true, message: "Please select status!" },
                        ]}
                      >
                        <Select>
                          <Select.Option value="Active">Active</Select.Option>
                          <Select.Option value="InActive">
                            Inactive
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Row justify="center">
                        <Col span={18} className="text-center">
                        <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                          <div className="mt-4">
                            {updateValue?.images && previewImg == null ? (
                              <div className="d-flex">
                                 <>
                                {chooseFiles
                                  ? chooseFiles.map((row,index) => {
                                      console.log(row, "row");
                                      return (
                                        <div className="d-inlne-block pr-2">
                                        <PreviewSeletedImages files={row} />
                                        <div className='text-white cursor-pointer'style={{ backgroundColor: "#FF5A5F" }} onClick={()=>del(row,index)}>delete</div>
                                        </div>
                                      );
                                    })
                                  : ""}
                                  </>
                                {" "}
                                {JSON.parse(updateValue.images).map((row,index) => {
                                  return (
                                    <div className="d-inline-block  pr-2">
                                      <Image
                                        width={100}
                                        className="border d-inline-block"
                                        src={`${process.env.REACT_APP_IMG_URL}bannerlist/${row}`}
                                      />
                                       <div className='text-white cursor-pointer'style={{ backgroundColor: "#FF5A5F" }} onClick={()=>imageDelete(row,index)}>delete</div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <>
                                {chooseFiles
                                  ? chooseFiles.map((row,index) => {
                                      console.log(row, "row");
                                      return (
                                        <div className="d-inline-block pr-2">
                                        <PreviewSeletedImages files={row} />
                                        <div className='text-white border cursor-pointer'style={{ backgroundColor: "#FF5A5F" }} onClick={()=>del(row,index)}>delete</div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Form.Item {...tailLayout}>
                        <Row justify="end" className="mt-5">
                          <Col>
                            <Button
                              type="primary"
                              className="btn-dark mr-3"
                              htmlType="submit"
                            >
                              {updateValue ? "Update " : " Add "}
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              type="btn text-white"
                              style={{ backgroundColor: "#FF5A5F" }}
                              onClick={() => {
                                // history.push("/rate-card-list");
                                setAddPost(false);
                                setUpdateValue(null);
                                setPreviewImg(null);
                                getBannerList();
                                // filesUpload = [];
                                form.resetFields();
                                // setChooseFiles(null);

                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          ) : (
            <div className="p-4">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-dark text-white"
                  onClick={() => {
                    setAddPost(true);
                    setChooseFiles([]);
                    form.setFieldsValue({ category_url:'',sub_category_url:'', type:'',  status: "Active" });
                  }}
                >
                  ADD
                </button>
              </div>
              <hr />
              <Table dataSource={dataSource} columns={columns} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerList;
