import { LOADING, USERTYPE } from '../action';
export const LoadReducer = (state = { loggedIn:false } , action) => {
    switch(action.type){
        case LOADING:
            return {
                ...state,
                padeLoad: action.payload
            }
        default:
            return state
    }
}

export const UserTypeReducer = (state = { userType:'main' } , action) => {
    switch(action.type){
        case USERTYPE:
            return {
                ...state,
                userType: action.payload
            }
        default:
            return state
    }
}