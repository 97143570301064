import React from 'react';
import { Layout, Breadcrumb } from 'antd';

export default function BreadcrumbNav(props) {
    const { pagename } = props;
    // console.log(pagename);
    return (
        <React.Fragment>
            <Breadcrumb style={{ margin: '16px 0' }}>
                {
                    pagename==="Category"?(
                        <React.Fragment>
                            <Breadcrumb.Item>dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active>category</Breadcrumb.Item>
                        </React.Fragment>
                    ):pagename==="sub-category"?(
                        <React.Fragment>
                            <Breadcrumb.Item>dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active>subcategory</Breadcrumb.Item>
                        </React.Fragment>
                    ):pagename==="Products"?(
                        <React.Fragment>
                            <Breadcrumb.Item>dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active>products</Breadcrumb.Item>
                        </React.Fragment>
                    ):pagename==="Contact"?(
                        <React.Fragment>
                            <Breadcrumb.Item>dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active>contact us</Breadcrumb.Item>
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                        <Breadcrumb.Item>dashboard</Breadcrumb.Item>
                        </React.Fragment>
                    )                
                }
            </Breadcrumb>
        </React.Fragment>
    )
}
