import React from 'react'
import {Input,Select} from "antd";
import './search.css';
export default function Search({dataSort, search, filter, filteredData, totalLength}) {
    return (
        <div className="row">
            <div className="search py-3">
                <div className="col-12  ">
                    <div className="d-flex align-items-center">
                    {filter?(<div className="col-auto pl-0 pr-5">{filteredData} Search results</div>):(<div className="col-auto pl-0 pr-5 ">{totalLength} results</div>)}
                        <div className="ml-3 col"> 
                        <div className="row justify-content-around">
                        <Input className="col-8" placeholder="Search home care ..."  value={filter} onChange={search} />
                        </div>
                        </div>
                    </div>
                </div>
               
                {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                    <div className="d-flex">
                        <p>Stort by: </p>
                        <div class=" col-8 px-0 ml-3"> 
                        <Select defaultValue="descending" style={{width:"100%"}} onChange={(e)=>{dataSort(e)}}>
                            <Select.Option value='descending'>Descending</Select.Option>
                            <Select.Option value='ascending'>Ascending</Select.Option>
                        </Select>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
