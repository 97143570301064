import React from 'react'
import Contactform from './contactform'
import { useHistory } from 'react-router-dom';
import Contactdata from './contactdata';
import './contact.css'
import Map from './map';

export default function Contact() {
    const history = useHistory();

    return (
            <div>
                {/* <div className="row">
                    <div className="col-12">
                        <img src="./assets/images/slider/b2.jpg" width="100%"/>
                    </div>
                </div> */}
           <div className="container">
            <div className="row">
                    <div className="col-12  breadcrumb_content">
                        <ol class="breadcrumb p-0  my-0 bg-transparent">
                                <li class="breadcrumb-item fs-14"><a onClick={() => { history.push("/home")}}>Home</a></li>
                                <li class="breadcrumb-item  fs-14 active text-thm" aria-current="page">Contact Us</li>
                            </ol>
                        <h2 class="breadcrumb_title  py-2">How can we help?</h2>
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-7">
                       <Contactform/>
                    </div>
                    <div className="col-5">
                        <Contactdata/>
                    </div>
                </div>
           <div className="row my-5">
                <Map/>
           </div>
           </div>
           </div>
    )
}
